<template>
  <confirm-modal
    v-if="showModal"
    :title="modalTitle"
    :message="modalMessage"
    @confirmed="onConfirmed"
    @declined="onDeclined"
  />
  <nav
    id="navbar"
    class="navbar navbar-expand-lg sticky-top navbar-dark break-out mb-5"
    style="background-color: #1f7aaf"
  >
    <div class="container-fluid">
      <div class="navbar-brand">
        <span v-if="isNotMainView && !countIsCompleted">
          <span
            v-if="
              (isCountInProcess && !isInstructionsPage) || isEditPositionMap
            "
          >
            <mdicon
              id="back-icon"
              v-on:click="$emit('navigate-backwards')"
              class="icon-link-icon"
              size="26"
              name="arrow-left"
            ></mdicon>
          </span>
          <a v-else class="icon-link" @click="$router.go(-1)">
            <mdicon class="icon-link-icon" size="26" name="arrow-left"></mdicon>
          </a>
          <div class="divider"></div>
        </span>

        <span>
          {{ title ? title : "DOFbasen" }}
        </span>
      </div>
      <div v-if="isCountInProcess && !isInstructionsPage">
        <button
          type="button"
          @click="$router.go(-1)"
          class="btn icon-link btn-small btn-danger bottom-action"
        >
          {{ $englishPreferred ? "Cancel count" : "Afbryd tælling" }}
        </button>
      </div>
      <div v-if="this.$route.path == '/'" class="text-light">
        <button class="btn" type="button" @click="logout">
          <mdicon class="icon-link" size="26" name="logout"></mdicon>
        </button>
      </div>
      <div v-if="isCollectionList">
        <button class="btn" type="button" @click="$emit('synchronize-list')">
          <mdicon class="icon-link" size="26" name="update"></mdicon>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import idb from "../../idb/idb.js";
import ConfirmModal from "../ConfirmModal.vue";

const storeNames = [
  "ages",
  "plumages",
  "species",
  "collections",
  "observations",
  "countPoints",
];

export default {
  props: {
    isCountInProcess: false,
    isInstructionsPage: false,
    countIsCompleted: false,
    isEditPositionMap: false,
    title: String,
  },
  components: {
    ConfirmModal,
  },
  data() {
    return {
      isCollectionList: this.$route.path == "/taelling/mine",
      showModal: false,
      modalTitle: "",
      modalMessage: "",
    };
  },
  methods: {
    showConfirmModal() {
      this.modalTitle = "Log ud";
      this.modalMessage = "Er du sikker på at du vil logge ud?";
      this.showModal = true;
    },
    async onConfirmed() {
      // Handle the OK button click here
      // This function is called when the user clicks "OK" in the modal
      this.showModal = false;

      // Clear user content in cache backup
      const cache = await caches.open("spontanCache");
      storeNames.forEach((storeName) => {
        cache.delete(storeName);
      });

      // Clear user content in indexedDB
      await idb.clearObjectStoreByName("observations");
      await idb.clearObjectStoreByName("countPoints");
      await idb.clearObjectStoreByName("collections");
      await idb.clearObjectStoreByName("generalAtlasSquareStatistics");
      await idb.clearObjectStoreByName("sitesChecksum");
      await idb.clearObjectStoreByName("speciesChecksum");

      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");

      window.location.reload();
    },
    onDeclined() {
      this.showModal = false;
    },
    async logout() {
      this.showConfirmModal();
    },
  },
  computed: {
    isNotMainView() {
      let isMainView = true;
      if (
        this.$route.path == "/" ||
        this.$route.path == "/daekningskort" ||
        this.$route.path == "/statistik" ||
        this.$route.path == "/indstillinger" ||
        this.$route.path == "/vejledning"
      ) {
        isMainView = false;
      }
      return isMainView;
    },
    isPartOfCountProcess() {
      let isPartOfCountProcess = false;
      if (
        this.$route.path == "/taelling/ny" ||
        this.$route.path == "/taelling/indtast"
      ) {
        isPartOfCountProcess = true;
      }
      return isPartOfCountProcess;
    },
  },
};
</script>

<style scoped>
#navbar {
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(45deg, #1f7aaf, #51af30);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(45deg, #1f7aaf, #51af30);
}

.icon-link-icon {
  position: relative;
  /* Adjust these values accordingly */
  bottom: 2px;
}

.icon-link {
  color: #ffff;
  cursor: pointer;
  display: inline-block;
}

#back-icon {
  cursor: pointer;
}
</style>
