<!-- ConfirmModal.vue -->
<template>
  <div
    class="modal fade"
    id="confirm-modal"
    tabindex="-1"
    role="dialog"
    ref="modal"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="decline"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          {{ message }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="decline"
            data-dismiss="modal"
          >
            Annuller
          </button>
          <button type="button" class="btn btn-primary" @click="confirm">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  props: {
    title: String,
    message: String,
  },
  data() {
    return {
      confirmModal: null,
    };
  },
  methods: {
    confirm() {
      console.log("confirmed");
      this.$emit("confirmed");
      this.confirmModal.hide();
    },
    decline() {
      console.log("declined");
      this.$emit("declined");
      this.confirmModal.hide();
    },
  },
  mounted() {
    this.confirmModal = new Modal(document.getElementById("confirm-modal"), {});
    this.confirmModal.show();
  },
};
</script>
