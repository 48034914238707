<template>
  <nav-header :title="title"></nav-header>
  <div class="container">
    <div class="mb-4" id="userStatisticsDiv">
      <div class="mb-4" id="headline">
        <h4>{{ $englishPreferred ? "Statistics" : "Statistik" }}</h4>
      </div>
      <div class="mb-4" id="myTotalCountsDiv" v-if="apiData != null">
        <b
          >{{
            $englishPreferred
              ? "My spontaneous counts total"
              : "Mine spontantællinger i alt"
          }}:</b
        ><br />
        <div class="row mb-2">
          <div class="col-6">
            {{ $englishPreferred ? "Amount" : "Antal" }}:
            {{ apiData.totalCounts }}
          </div>
          <div align="right" class="col-6">
            <router-link to="/statistik/rang/taellinger/total"
              ><button class="btn-xs btn-primary btn-slim">
                {{ $englishPreferred ? "See ranking" : "Se rangliste" }}
                <mdicon class="icon-link" name="medal"></mdicon></button
            ></router-link>
          </div>
        </div>
        <div v-if="seePoints == 'true'" class="row">
          <div class="col-6">Point: {{ apiData.totalPoints }}</div>
          <div align="right" class="col-6">
            <router-link to="/statistik/rang/point/total"
              ><button class="btn-xs btn-primary btn-slim">
                {{ $englishPreferred ? "See ranking" : "Se rangliste" }}
                <mdicon class="icon-link" name="medal"></mdicon></button
            ></router-link>
          </div>
        </div>
      </div>
      <div class="mb-4" id="myCountsInCurrentYear" v-if="apiData != null">
        <b
          >{{
            $englishPreferred
              ? "My spontaneous counts in "
              : "Mine spontantællinger i "
          }}{{ currentYear }}:</b
        ><br />
        <div class="row mb-2">
          <div class="col-6">
            {{ $englishPreferred ? "Amount" : "Antal" }}:
            {{ apiData.totalCountsCurrentYear }}
          </div>
          <div align="right" class="col-6">
            <router-link to="/statistik/rang/taellinger/aar"
              ><button class="btn-xs btn-primary btn-slim">
                {{ $englishPreferred ? "See ranking" : "Se rangliste" }}
                <mdicon class="icon-link" name="medal"></mdicon></button
            ></router-link>
          </div>
        </div>
        <div v-if="seePoints == 'true'" class="row">
          <div class="col-6">Point: {{ apiData.totalPointsCurrentYear }}</div>
          <div align="right" class="col-6">
            <router-link to="/statistik/rang/point/aar"
              ><button class="btn-xs btn-primary btn-slim">
                {{ $englishPreferred ? "See ranking" : "Se rangliste" }}
                <mdicon class="icon-link" name="medal"></mdicon></button
            ></router-link>
          </div>
        </div>
      </div>
      <div id="myRecentCounts" v-if="apiData != null">
        <b
          >{{
            $englishPreferred
              ? "My spontaneous counts recent 30 days"
              : "Mine spontantællinger de sidste 30 dage"
          }}:</b
        ><br />
        <div class="row mb-2">
          <div class="col-6">
            {{ $englishPreferred ? "Amount" : "Antal" }}:
            {{ apiData.totalCounts30Days }}
          </div>
          <div align="right" class="col-6">
            <router-link to="/statistik/rang/taellinger/30"
              ><button class="btn-xs btn-primary btn-slim">
                {{ $englishPreferred ? "See ranking" : "Se rangliste" }}
                <mdicon class="icon-link" name="medal"></mdicon></button
            ></router-link>
          </div>
        </div>
        <div class="row" v-if="seePoints == 'true'">
          <div class="col-6">Point: {{ apiData.totalPoints30Days }}</div>
          <div align="right" class="col-6">
            <router-link to="/statistik/rang/point/30"
              ><button class="btn-xs btn-primary btn-slim">
                {{ $englishPreferred ? "See ranking" : "Se rangliste" }}
                <mdicon class="icon-link" name="medal"></mdicon></button
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="apiData != null">
      <div class="mb-4">
        <p>
          {{
            $englishPreferred ? "My covered squares" : "Mine dækkede kvadrater"
          }}:<br /><b>{{ apiData.coveredSquaresTotal }}</b>
          {{ $englishPreferred ? "of" : "ud af" }} <b>2255</b>
        </p>
      </div>

      <div class="mb-4">
        <p>
          {{
            $englishPreferred
              ? "My covered squares in "
              : "Mine dækkede kvadrater I "
          }}{{ currentYear }}:<br /><b>{{
            apiData.coveredSquaresCurrentYear
          }}</b>
          {{ $englishPreferred ? "of" : "ud af" }} <b>2255</b>
        </p>
      </div>

      <div class="mb-4">
        <p>
          {{
            $englishPreferred
              ? "My covered squares recent 30 days"
              : "Mine dækkede kvadrater de sidste 30 dage"
          }}:<br /><b>{{ apiData.coveredSquares30Days }}</b>
          {{ $englishPreferred ? "of" : "ud af" }} <b>2255</b>
        </p>
      </div>

      <div align="center" class="" id="generalStatisticsButtonDiv">
        <router-link to="/statistik/projekt" class="btn btn-default btn-lg">{{
          $englishPreferred
            ? "General project statistics"
            : "Generel projektstatistik"
        }}</router-link>
      </div>
    </div>
  </div>
  <br />
</template>

<script>
import NavHeader from "@/components/navbars/NavHeader.vue";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    NavHeader,
  },
  data() {
    return {
      online: true,
      title: this.$englishPreferred ? "My statistics" : "Min statistik",
      currentYear: new Date().getFullYear(),
      apiData: null,
      seePoints: "true",
      moment: moment,
    };
  },
  methods: {
    async getIndividualStatistics() {
      return axios.get("/spontan/ranking/statistics/individual");
    },

    async prepareData() {
      this.getIndividualStatistics()
        .then((response) => {
          this.apiData = response.data;
        })
        .catch(() => {
          console.log("Server did not respond, updating locally");
          this.online = false;
          this.apiData = 0;
        });
    },
  },

  mounted() {
    this.prepareData();
    if ($cookies.isKey("seePoints")) {
      this.seePoints = $cookies.get("seePoints");
    }
  },
};
</script>
